import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { motolog } from '../../api/motolog'

export const upsertVehicle = createAsyncThunk( 'vehicles/upsertVehicle', async (vehicle) => {
  try {
    let { data, error } = await motolog.from('vehicles').upsert(vehicle)
    if (error) {
      throw error
    }
    return data
  } catch (error) {
    alert(`${error.message} for ${motolog.auth.uid}`)
    console.log(error)
  }
})

export const getVehicle = createAsyncThunk( 'vehicles/getVehicle', async (vehicle_id) => {
  try {
    let { data, error, status } = await motolog
      .from('vehicles')
      .select('id, name, year, make, model, details')
      .eq('id', vehicle_id)
      .single()
    if (error && status !== 406) {
      throw error
    }
    if (data) {
      return data
    }
  } catch (error) {
    alert(`${error.message} for ${motolog.auth.uid}`)
  }
})

export const getVehicles = createAsyncThunk( 'vehicles/getVehicles', async () => {
  try {
    return await motolog.from('vehicles').select()
  } 
  catch (error) {
    alert(`${error.message} for ${motolog.auth.uid}`)
  }
})

const initialState = {
  vehicles: { vehicles: [] },
  currentVehicle: {},
  isEditing: false,
  error: {},
  status: 'idle'
}

const vehicleSlice = createSlice({
  name: 'vehicles',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getVehicle.fulfilled, (state, action) => {
        state.currentVehicle = action.payload
        state.status = 'success'
      })
      .addCase(upsertVehicle.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(upsertVehicle.fulfilled, (state, action) => {
        const previousVehicleId = state.vehicles.findIndex(
          (v) => v.id === action.vehicle.vehicle.id
        )
        previousVehicleId
          ? state.vehicles[previousVehicleId] = action
          : state.vehicles.push(action.payload)
      })
      .addCase(getVehicle.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getVehicle.rejected, (state, action) => {
        state.status = `faild to getVehicle: ${action}`
      })
  },
})

export const selectAllVehicles = (state) => state.vehicles?.vehicles
export const selectCurrentVehicle = (state) => state.vehicles?.currentVehicle
export const getVehicleStatus = (state) => state.vehicles.status
export default vehicleSlice.reducer
