import { useState} from 'react'
import { TextInput, Button, Box, Popover, PasswordInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import {logIn, selectOwnerStatus, selectError} from './ownerSlice'
import {useDispatch, useSelector} from 'react-redux'

export default function Auth() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const status = useSelector(selectOwnerStatus)
  const error = useSelector(selectError)
  const dispatch = useDispatch()

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })
  const handleLogin = async () => {
    dispatch(logIn({email, password}))
  }

  if (!password && status === 'magic-link') {
    return (
      <Popover opened={true} > 
        Sending Magic Link to {email}
        {error}
      </Popover>
    )
  }

  return (

    <Box sx={{ maxWidth: 300 }} mx="auto">
      <p className="description">Sign in via magic link with your email below</p>
      <form onSubmit={form.onSubmit(handleLogin)}>
        <TextInput mt="md" label="Email Address" onKeyUp={(e) => setEmail(e.target.value)} {...form.getInputProps('email')}  />
        <small>Hint: If you don&apos;t know your password, just enter your email and we will send a login link </small>
        <PasswordInput mt="md" label="Password" onKeyUp={(e) => setPassword(e.target.value)} {...form.getListInputProps('password')} />
        <br />
        <Button id='submit' type="submit" >Submit</Button>
      </form>
    </Box>
  )
}