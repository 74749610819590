import { MantineProvider, AppShell} from '@mantine/core'
import { Route, Routes} from 'react-router-dom'
import Auth from './features/owners/Auth'
import Account from './features/owners/Account'
import Vehicles from './features/vehicles/Vehicles'
import Vehicle from './features/vehicles/Vehicle'
import Header from './features/header/Header'
import { useSelector, useDispatch } from 'react-redux'
import {useEffect} from 'react'
import {selectSession, getSession} from './features/owners/ownerSlice'
import {motolog} from './api/motolog'

export default function App() {
  const session = useSelector(selectSession)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSession())
    motolog.auth.onAuthStateChange(() => {
      dispatch(getSession())
    })
  },[])

  if (session) {
    return (
      <AppShell
        padding="md"
        header={<Header />}
        styles={(theme) => ({
          main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
        })}>


        <Routes>
          <Route element={<Vehicles session={session} />} path="/" />
          <Route element={<Auth />} path="/auth" />
          <Route element={<Account key={session?.user?.id} session={session} />} path="/account" />
          <Route element={<Vehicle session={session} />} path="/vehicle/:vehicle_id" />
          <Route element={<Vehicles session={session} />} path="/vehicles" />
        </Routes>
      </AppShell>
    )
  } else {
    return (
      <MantineProvider theme={{ fontFamily: 'Open Sans, sans serif', spacing: { xs: 15, sm: 20, md: 25, lg: 30, xl: 40 }, }} >
        <Auth />
      </MantineProvider>
    )
  }
}