import { configureStore } from '@reduxjs/toolkit'
import vehicleReducer from '../features/vehicles/vehicleSlice'
import ownerReducer from '../features/owners/ownerSlice'
import logEntryReducer from '../features/vehicles/logEntrySlice'

export const store = configureStore({
  reducer: {
    vehicles: vehicleReducer,
    owner: ownerReducer,
    log_entries: logEntryReducer,
  },
})
