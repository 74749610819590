import {HeaderResponsive} from './HeaderResponsive'
import { logOut } from '../owners/ownerSlice'
import {useDispatch} from 'react-redux'

export default function Header() {
  const dispatch = useDispatch
  const logout = () => {
    console.log('logging out')
    dispatch(logOut)
  }
  return (
    <HeaderResponsive p="xs" links={[
      { label:'Account', link: '/account'},
      { label: 'My Vehicles', link: '/vehicles'},
      { label: 'Log Out', link: '/', callback: logout }
    ]} />
  )
}