import  {useEffect } from 'react'
import { TextInput, Button, Group, Box} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { selectOwner, getOwner, setOwner, logOut } from './ownerSlice'
import _ from 'lodash'

function Account ()  {
  const dispatch = useDispatch()
  const owner = useSelector(selectOwner)
  
  useEffect(() => {
    dispatch(getOwner())
  },[])
  
  useEffect(() => {
    if (owner) {
      form.setValues(owner)
    }
  },[owner])

  const form = useForm({
    initialValues: {...owner},
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  const updateProfile = async (e) => {
    const updateOwner = _.pick(e, [ 'id', 'username', 'website', 'avatar_url', 'password'])
    dispatch(setOwner(updateOwner))
  }
  const logout = async () => {
    console.log('logging out')
    dispatch(logOut())
  }

  return (
    <Box sx={{ maxWidth: 300 }} mx="auto">
      <form onSubmit={form.onSubmit(updateProfile)}>
        <h2>{owner?.email}</h2>
        <TextInput mt="md" label="Username" {...form.getInputProps('username')}  />

        <TextInput mt="md" label="Website" {...form.getInputProps('website')} />

        <TextInput mt="md" label="Avatar URL" {...form.getInputProps('avatar_url')} />
        <TextInput mt="md" label="Reset Password" {...form.getInputProps('password')} />

        <Group position="right" mt="md">
          <Button type="submit">Submit</Button>
          <Button type="button" className="button block red" onClick={() => logout() }>
                Sign Out
          </Button>
        </Group>
      </form>
    </Box>
  )
}

export default Account