import { Table } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'
import 'moment-timezone'
import LogEntry from './LogEntry'
import {getLogEntries, selectLogEntries } from './logEntrySlice'
import {selectCurrentVehicle } from './vehicleSlice'

export default function LogEntries() {
  const vehicleId = useSelector(selectCurrentVehicle).id
  
  const log_entries = JSON.parse(JSON.stringify(useSelector(selectLogEntries)))
  const [entryModal, setEntryModal] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLogEntries(vehicleId))
  }, [vehicleId])


  
  const intervalFor = (entry) => {
    const interval = entry.repeat_interval
    if (!interval) { return }
    const keys = Object.keys(interval)
    return keys.map((key) => {
      return `${interval[key]} ${key}`
    }).join(', ')
  }
  return (
    <>
      <h2 style={{borderBottom: '1px solid black'}}> History </h2>
      <Table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Repeat Interval</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {log_entries?.map((entry) => (
            <tr key={entry.id}>
              <td> 
                <LogEntry logEntry={entry} modal={[entryModal, setEntryModal]} vehicle_id={vehicleId} />
              </td>
              <td>{intervalFor(entry)}
              </td>
              <td><Moment fromNow >{entry.start_date}</Moment></td>
              <td><Moment fromNow>{entry.end_date}</Moment></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LogEntry   vehicleId={vehicleId} />
    </> 
  )


}