import {useState, useEffect} from 'react'
import { Table } from '@mantine/core'
import {motolog} from '../../api/motolog'
import {useNavigate} from 'react-router-dom'
import AddVehicle from './addVehicle'
import {randomId} from '@mantine/hooks'

export default function Vehicles() {
  const [vehicles, setVehicles] = useState()
  const [, setLoading] = useState()
  const navigate = useNavigate()
    
  useEffect(() => {
    getVehicles()
  }, [])

  const getVehicles = async () => {
    try {
      setLoading('visible')
      const user = motolog.auth.user()
      if (!user) {return}
      let { data, error, status } = await motolog
        .from('vehicles')
        .select('id, year, make, model')
        .eq('owner_id', user.id)

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setVehicles(data)
      }
    } catch (error) {
      alert(`${error.message} for ${motolog.auth.uid}`)
    } finally {
      setLoading('none')
    }
  }

  if ( !vehicles) {return (<></>)}
  return (
    <>
      <Table striped highlightOnHover>
        <tbody>
          {vehicles?.map((vehicle) => (
            <tr id={vehicle.id ? vehicle.id : randomId()} key={vehicle?.id} onClick={() => navigate(`/vehicle/${vehicle?.id}`)}>
              <td> {vehicle.make} </td>
              <td> {vehicle.model} </td>
              <td>  {vehicle.year} </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <br/>
      <AddVehicle />
    </>
  )
}