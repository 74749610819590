import {
  Group,
  ActionIcon,
  Modal,
  Button,
  TextInput,
  Textarea,
  NumberInput,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useState, useEffect } from 'react'
import { useForm, formList } from '@mantine/form'
import { motolog } from '../../api/motolog'
import { Pencil, Plus, Trash } from 'tabler-icons-react'
import { randomId } from '@mantine/hooks'
import { useDispatch } from 'react-redux'
import { upsertVehicle, getVehicle } from './vehicleSlice'

export default function AddVehicle({vehicle}) {
  const dispatch = useDispatch()
  const [opened, setOpened] = useState()
  

  useEffect(() => {dispatch(getVehicle)},[])

  const details = formList( vehicle?.details ? [...vehicle.details] : [{ key: randomId(), value: undefined, label: undefined }])

  const form = useForm({
    initialValues: {
      owner_id: motolog.auth.user().id,
      name: vehicle?.name || '',
      make: vehicle?.make || '',
      model: vehicle?.model || '',
      year: vehicle?.year || 0,
      purchaseDate: vehicle?.purchaseDate || new Date(),
      baseMilage: vehicle?.baseMilage || '',
      details: details || [{key: randomId(), value: '', label: ''}],
    },
  })
  const createVehicle = async (values) => {
    if (vehicle.id) values.id = vehicle.id
    dispatch(upsertVehicle(values))
    setOpened(false)
  }

  const fields = form.values.details.map((item, index) => (
    <Group key={item.key} mt="xs">
      <TextInput
        placeholder="Electrical"
        required
        sx={{ flex: 1 }}
        {...form.getListInputProps('details', index, 'label')}
      />
      <Textarea
        placeholder="12V DC"
        autosize
        sx={{ flex: 4 }}
        {...form.getListInputProps('details', index, 'value')}
      />
      <ActionIcon
        color="red"
        variant="hover"
        onClick={() => form.removeListItem('details', index)}
      >
        <Trash size={16} />
      </ActionIcon>
    </Group>
  ))

  return (
    <>
      <Modal key={`addVehicle-${vehicle?.id}`}
        size="full"
        opened={opened}
        onClose={() => setOpened(false)}
        title={vehicle?.id ? 'Edit Your Vechicle' : 'Add a Vehicle!'}
      >
        <form onSubmit={form.onSubmit((values) => createVehicle(values))}>
          <TextInput label="Name" {...form.getInputProps('name')} />
          <TextInput label="Make" {...form.getInputProps('make')} />
          <TextInput label="Model" {...form.getInputProps('model')} />
          <TextInput label="Year" {...form.getInputProps('year')} />
          <DatePicker
            label="PurchaseDate"
            {...form.getInputProps('purchaseDate')}
          />
          <NumberInput
            label="BaseMilage"
            {...form.getInputProps('baseMilage')}
          />
          <h3 style={{ borderBottom: '1px solid grey' }}>Details</h3>
          {fields}
          <Group position="center" mt="md">
            <Button
              onClick={() =>
                form.addListItem('details', {
                  label: '',
                  value: '',
                  key: randomId(),
                })
              }
            >
              {' '}
              Add another detail
            </Button>
          </Group>
          <br />
          <Button type="submit">Create</Button>
        </form>
      </Modal>
      <AddEditButton vehicle={vehicle} setOpened={setOpened} />
    </>
  )
}

function AddEditButton({ vehicle, setOpened }) {
  if (vehicle?.id) {
    return (
      <Button
        variant="subtle"
        onClick={() => setOpened(true)}
        leftIcon={<Pencil />}
      >
        {' '}
        edit{' '}
      </Button>
    )
  } else {
    return (
      <Button
        variant="subtle"
        onClick={() => setOpened(true)}
        leftIcon={<Plus />}
      >
        {' '}
        Create New Vehicle{' '}
      </Button>
    )
  }
}
