import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { motolog } from '../../api/motolog'

export const upsertLogEntry = createAsyncThunk('log_entries/upsertLogEntry', async (log_entry) => {
  try {
    log_entry.repeat_interval = JSON.parse(log_entry.repeat_interval)
    let { data } = await motolog.from('log_entries').upsert(log_entry).single()
    if (data) {return data}
    return {}
  } catch (error) {
    return error.message
  }
})

export const getLogEntries = createAsyncThunk('log_entries/getLogEntries', async (vehicleId) => {
  try {  
    let { data, error, status } = await motolog
      .from('log_entries').select().eq('vehicle_id', vehicleId)
    if (error && status !== 406) { throw error }
    if (data) { return data}
  } catch (error) {
    alert(`${error.message} for ${motolog.auth.uid}`)
  }
})

const initialState = {
  current_log_entry: {},
  log_entries: [{}],
  state: 'idle',
  error: {}
}

const logEntrySlice = createSlice({
  name: 'log_entries',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getLogEntries.fulfilled, (state, action) => {
        state.log_entries = action.payload
        state.state = 'success'
      })
      .addCase(getLogEntries.rejected, (state) => {
        state.state = 'failed'
      })
      .addCase(upsertLogEntry.fulfilled, (state, action) => {
        state.current_log_entry = action.payload
        state.log_entries = [...state.log_entries.filter((l) => (l.id != action.meta.arg.id)), action.payload]
        state.state = 'success'
      })
      .addCase(upsertLogEntry.rejected, (state) => {
        state.state = 'failed'
      })
      
  }
})

export default logEntrySlice.reducer
export const selectLogEntries = (state) => state.log_entries.log_entries
export const selectLogEntryState = (state) => state.log_entries.state