import { useState, useEffect } from 'react'
import { Tabs } from '@mantine/core'
import { useParams } from 'react-router-dom'
import LogEntries from './LogEntries'
import ReactMarkdown from 'react-markdown'
import AddVehicle from './addVehicle'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentVehicle, getVehicle} from './vehicleSlice'

export default function Vehicle() {
  const dispatch = useDispatch()
  const { vehicle_id } = useParams()
  const [activeTab, setActiveTab] = useState(1)
  const vehicle = useSelector(selectCurrentVehicle)

  useEffect(() => {
    dispatch(getVehicle(vehicle_id))
  },[])

  window.vehicle = vehicle
  if ( vehicle?.details) {
    return (
      <div>
        <h1>
          {vehicle?.name ? vehicle.name : ''} {vehicle?.year} {vehicle?.make}{' '}
          {vehicle?.model}
          <AddVehicle vehicle={vehicle} />
        </h1>
        <Tabs active={activeTab} onTabChange={setActiveTab}>
          { details(vehicle?.details) }
        </Tabs>
        <LogEntries vehicle_id={vehicle_id} />
      </div>
    )
  }
}

const details = (details) => {
  if (!details?.length) {
    return <></>
  }
  return details.map(({ key, value, label }) => (
    <Tabs.Tab key={key} label={label}>
      <ReactMarkdown>{value}</ReactMarkdown> 
    </Tabs.Tab>
  ))
}
