import { createClient } from '@supabase/supabase-js'
const supabaseUrl = 'https://zffeaihdhmnbfxjlxelf.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpmZmVhaWhkaG1uYmZ4amx4ZWxmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTQ2MzA4OTYsImV4cCI6MTk3MDIwNjg5Nn0.4NSsd301MfHhk1K1Fhm8NPcGfVrGa_DBwx7yus3B97s'
export const motolog = createClient(supabaseUrl, supabaseAnonKey)

export const getVehicle = async (vehicle_id) => {
  try {
    let { data, error, status } = await motolog
      .from('vehicles')
      .select('id, year, make, model')
      .eq('id', vehicle_id)
      .single()
    if (error && status !== 406) { throw error }
    if (data) { return (data) }
  } catch (error) {
    alert(`${error.message} for ${motolog.auth.uid}`)
  }
}