import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { motolog } from '../../api/motolog'
import process from 'process'

export const getOwner = createAsyncThunk('owner/getOwner', async () => {
  try {  
    const user = motolog.auth.user()
    if (!user) {return}
    const {data}  = await motolog.from('owners').select().eq('id', user.id).single()
    return {...data, email: user.email, id: user.id}
  }
  catch(error) {
    alert(error.message)
  }
})
export const setOwner = createAsyncThunk('owner/setOwner', async (updateOwner) => {
  try { 
    if (updateOwner.password) {
      await motolog.auth.update({password: updateOwner.password, email: updateOwner.email})
      delete updateOwner.password
    }
     
    const {data} = await motolog.from('owners').upsert(updateOwner)
    if (data) {return data}
  }
  catch(error) {return error}
})

export const getSession = createAsyncThunk('owner/getSession', async () => {
  try {
    return motolog.auth.session()
  } catch (error) {
    alert(error.message)
  }
})

export const logOut = createAsyncThunk('owner/logOut', async () => {
  try {
    await motolog.auth.signOut()
  } catch (error) {
    alert(error.message) 
  }
})

export const logIn = createAsyncThunk('owner/logIn', async ({email, password}) => {
  try {
    const auth_params = password  ? {email: email, password: password} : {email: email} 
    let {data , error} = await motolog.auth.signIn(auth_params, {
      redirectTo: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://motolog.tuckerbradford.com/'
    })
    if (error) {throw error}
    if (data) {return data}
    return 'magic-link'

  } catch (error) { 
    return error 
  }
})

const initialState = {
  owner: {},
  isEditing: false,
  error: {},
  status: 'idle', 
  session: null
}

const ownerSlice = createSlice({
  name: 'owner',
  initialState,
  
  extraReducers(builder) {
    builder
      .addCase(getOwner.fulfilled, (state, action) => {
        state.status = 'success'
        state.owner = action.payload
      })
      .addCase(getOwner.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })
      .addCase(setOwner.fulfilled, (state, action) => {
        state.status = 'success'
        state.owner = action.payload
      })
      .addCase(logOut.fulfilled, () => {
        return {}
      })
      .addCase(logIn.fulfilled, (state, action) => {
        if (action.status === 400) {
          state.error = action.message
          state.status = 'login failed'
        } else if (action.status === 429) {
          state.error = `failed ${action.message}`
          state.status = 'magic-link'
        } else if (action.payload === 'magic-link') {
          state.error = 'Sending Magic Link'
          state.status = 'magic-link'
        } else {
          state.status = 'login success'
        }
      })
      .addCase(getSession.fulfilled, (state, action) => {
        state.status = 'login success'
        state.session = action.payload
      })
  }
})


export const selectOwner = (state) => state.owner.owner
export const selectOwnerStatus = (state) => state.owner.status
export const selectSession = (state) => state.owner.session
export const selectError = (state) => state.error

export default ownerSlice.reducer