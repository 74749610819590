import {
  Anchor,
  Modal,
  Button,
  Grid,
  TextInput,
  JsonInput,
  Textarea,
  Group,
  ActionIcon,
  createStyles,
} from '@mantine/core'
import { DateRangePicker } from '@mantine/dates'
import { useState, useEffect } from 'react'
import { useForm, formList } from '@mantine/form'
import moment from 'moment'
import { randomId } from '@mantine/hooks'
import { Pencil, Plus, Trash } from 'tabler-icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { upsertLogEntry } from './logEntrySlice'
import {selectCurrentVehicle} from './vehicleSlice'
import {selectOwner, getOwner} from '../owners/ownerSlice'

export default function LogEntry({ logEntry }) {
  const dispatch = useDispatch()
  const useStyles = createStyles(() => ({
    logEntryLabel: {
      input: {
        fontWeight: 700,
        fontSize: 16,
        fontFamily: 'Lato, sans-serif',
      },
      h3: {},
    },
    parts: {
      border: '2px solid lightgrey',
      padding: '1rem',
      margin: '1rem',
      h3: { backgroundColor: '#fefefe' },
    },
  }))
  const [opened, setOpened] = useState()
  const vehicle = {...useSelector(selectCurrentVehicle)}
  const user = useSelector(selectOwner)
  const [submit, setSubmit] = useState({ display: 'none' })

  useEffect(() => {
    user ? dispatch(getOwner()) : ''
    form.setFieldValue('owner_id', user.id)
  }, [])

  const parts = formList(
    logEntry?.parts
      ? logEntry.parts
      : [{ key: randomId(), value: '', label: '', quantity: 0 }]
  )
  const form = useForm({
    initialValues: {
      id: logEntry?.id,
      title: logEntry?.title,
      start_date: logEntry?.start_date || new Date(),
      end_date: logEntry?.end_date || new Date(),
      milage: logEntry?.milage,
      parts: parts,
      notes: logEntry?.notes,
      repeat_interval: JSON.stringify(logEntry?.repeat_interval),
      owner_id: logEntry?.owner_id || user.id,
      vehicle_id: vehicle?.id,
    },
  })

  useEffect( () => {
    form.setFieldValue('repeat_interval', JSON.stringify(logEntry?.repeat_interval))
  }, [logEntry])

  useEffect(() => {
    if (form.initialValues !== form.values) {
      setSubmit({ display: 'block' })
    }
  }, [form?.values])
  

  const handleRangeChange = (e) => {
    form.setFieldValue('start_date', moment(e[0]).toISOString())
    form.setFieldValue(
      'end_date',
      e[1] ? moment(e[1]).toISOString() : moment(e[0]).toISOString()
    )
  }
  
  const createLogEntry = async (e) => {
    dispatch(upsertLogEntry(e))
    setOpened(false)
  }

  const verticalCenter = { marginTop: 'auto', marginBottom: 'auto' }
  const { classes } = useStyles()

  const PartsFields = form.values.parts.map((item, index) => (
    <Grid  size="full" key={item.key}>
      <Grid.Col span={1}>
        <TextInput
          className={classes.logEntryLabel}
          variant="unstyled"
          placeholder="Oil"
          sx={{ flex: 1 }}
          {...form.getListInputProps('parts', index, 'label')}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <TextInput
          style={{ fontWeight: 'bold' }}
          variant="unstyled"
          placeholder="quantity"
          required
          sx={{ flex: 1 }}
          {...form.getListInputProps('parts', index, 'quantity')}
        />
      </Grid.Col>
      <Grid.Col span={7}>
        <Textarea
          placeholder="5W20"
          sx={{ flex: 1 }}
          {...form.getListInputProps('parts', index, 'value')}
        />      </Grid.Col>
      <Grid.Col span={2} >
        <ActionIcon color="red" variant="hover" onClick={() => form.removeListItem('parts', index)}  value="Add Part(s)">
          <Trash size={16} />
        </ActionIcon>
      </Grid.Col>
    </Grid>
  ))

  return (
    <div key={vehicle?.id}>
      {logEntry?.title}
      <Modal size="full" opened={opened} onClose={() => setOpened(false)}>
        <form onSubmit={form.onSubmit((values) => createLogEntry(values))}>
          <Grid>
            <Grid.Col span={12}>
              <input
                type="hidden"
                id="owner_id"
                name="owner_id"
                value={user?.id || ''}
              />
              <input
                type="hidden"
                id="vehicle.id"
                name="vehicle.id"
                value={parseInt(vehicle.id) || ''}
              />
              <Grid>
                <Grid.Col span={3}>
                  {' '}
                  <strong>Title</strong>{' '}
                </Grid.Col>
                <Grid.Col style={verticalCenter} span={6}>
                  <TextInput
                    variant="unstyled"
                    placeholder="Oil Change"
                    {...form.getInputProps('title')}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={3}>
                  <strong>Milage</strong>{' '}
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    variant="unstyled"
                    placeholder="50,000"
                    {...form.getInputProps('milage')}
                  />{' '}
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={3}>
                  {' '}
                  <strong>Notes</strong>{' '}
                </Grid.Col>
                <Grid.Col span={6}>
                  {' '}
                  <Textarea
                    variant="unstyled"
                    {...form.getInputProps('notes')}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={3}>
                  {' '}
                  <strong>Repeat Interval </strong>{' '}
                </Grid.Col>
                <Grid.Col span={6}>
                  <JsonInput
                    variant="unstyled"
                    id="repeat_interval"
                    {...form.getInputProps('repeat_interval') }
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={4}>
                  <strong>Date (range) of maintenance</strong>
                </Grid.Col>
                <Grid.Col span={8}>
                  <DateRangePicker
                    onChange={handleRangeChange}
                    value={[
                      new Date(form.values.start_date),
                      new Date(form.values.end_date),
                    ]}
                  />
                </Grid.Col>
              </Grid>

              <h3> Parts </h3>
              <br/>
              {PartsFields}
              
              <Button onClick={() => form.addListItem('parts', { label: '', value: '', quantity: '', key: randomId(), }) } >
                {' '}
                <Plus size={16} />
              </Button>
            </Grid.Col>
            <Group>
              <Button id="submit" style={submit} type="submit">
                {logEntry ? 'Update' : 'Create'}{' '}
              </Button>
              <Button
                style={{ flexAlign: 'right' }}
                variant="unstyled"
                id="cancel"
                onClick={() => setOpened(false)}
              >
                Done
              </Button>
            </Group>
          </Grid>
        </form>
      </Modal>

      <Anchor to="" onClick={() => setOpened(true)}>
        {logEntry ? (
          <>
            &nbsp; <Pencil size={16} />
          </>
        ) : (
          <>
            <br />
            <Plus style={{ verticalAlign: 'middle' }} size={16} /> Add New Log
            Entry
          </>
        )}
      </Anchor>
    </div>
  )
}
